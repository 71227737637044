import { action, computed, makeObservable, observable } from "mobx";
import * as leadContactsService from "../requests/leads/contacts";
import { underScoreToCamelCase } from "../utils/commons";

export class ContactsStore {
  @observable contacts = [];

  @observable isLoading = false;

  constructor(leadDetailsStore) {
    makeObservable(this);
    this.leadDetailsStore = leadDetailsStore;
    this.bannerStore = this.leadDetailsStore.rootStore.bannerStore;
  }

  @computed get emails() {
    return this.contacts
      .map((contact) => {
        if (contact.attributes && contact.attributes.email) {
          return contact.attributes.email;
        }
        return null;
      })
      .filter((email) => email);
  }

  @computed get primaryContact () {
    return this.contacts.find((contact) => contact.attributes.primaryContact);
  }

  @action
  async getContacts() {
    const leadId = this.leadDetailsStore.leadId;

    this.setIsLoading(true);

    try {
      const contacts = await leadContactsService.getContacts(leadId);

      this.setContacts(contacts);
    } catch (err) {
      this.bannerStore.addBanner(
        "red",
        "Contacts failed to load",
        "The contacts could not be retrieved. Wingmate Support has been notified and is looking into the issue."
      );
    }

    this.setIsLoading(false);
  }

  @action
  getContact(contactId) {
    const contact = this.contacts.find(
      (contact) => contact.id.toString() === contactId.toString()
    );

    return contact;
  }

  @action
  getContactIndex(contactId) {
    const contactIndex = this.contacts.findIndex(
      (contact) => contact.id.toString() === contactId.toString()
    );

    return contactIndex;
  }

  @action
  async createContact() {
    const leadId = this.leadDetailsStore.leadId;

    this.setIsLoading(true);

    const contactPayload = {
      lead_id: leadId,
    };

    const contacts = await leadContactsService.postContact(
      leadId,
      contactPayload
    );

    this.setContacts(contacts);

    this.setIsLoading(false);
  }

  @action
  async deleteContact(contactId) {
    const leadId = this.leadDetailsStore.leadId;

    this.setIsLoading(true);

    const contacts = await leadContactsService.deleteContact(leadId, contactId);

    this.setContacts(contacts);

    this.setIsLoading(false);
  }

  @action
  initialContactsStoreUpdate(contactId, payload) {
    const contactIndex = this.getContactIndex(contactId);
    Object.entries(payload).map((attribute) => {
      const field = underScoreToCamelCase(attribute[0]);
      const value = attribute[1];
      this.contacts[contactIndex].attributes[field] = value;
    });
  }

  async updateContact(contactId, payload, previousPayload) {
    this.initialContactsStoreUpdate(contactId, payload);
    const leadId = this.leadDetailsStore.leadId;

    this.setIsLoading(true);

    try {
      const contacts = await leadContactsService.putContact(
        leadId,
        contactId,
        payload
      );

      this.setContacts(contacts);
    } catch (err) {
      this.initialContactsStoreUpdate(contactId, previousPayload);
      throw err;
    }

    this.setIsLoading(false);
  }

  @action
  setContacts(contacts) {
    this.contacts = contacts;
  }

  @action
  setIsLoading(loading) {
    this.isLoading = loading;
  }
}

export default ContactsStore;

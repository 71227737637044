import { TFunction } from "i18next";
import { IUserAttributes } from "../types/IUser";

export const getUsername = (user?: Pick<IUserAttributes, "fullName">) => {
  let username;

  if (user) username = user.fullName;

  return username;
};

export const getNameOfUser = (
  t: TFunction,
  user?: Pick<IUserAttributes, "fullName" | "disabled">,
) => {
  const username = getUsername(user);

  if (user) {
    if (user.disabled) {
      return t("common:user.disabled", { name: username });
    }
    return username;
  }

  return t("common:user.deletedUser");
};

export default {
  getNameOfUser,
  getUsername,
};

import {
  action, computed, makeObservable, observable,
} from "mobx";
import { RootStore } from "./RootStore";
import {
  IMassUpdate, IMassUpdateAttributes,
} from "../types/IMassUpdate";
import { Page } from "../types/Meta";
import { IMassUpdatePayload } from "../requests/massUpdates";
import * as campaignMassUpdateService from "../requests/campaigns/massUpdates";
import * as massUpdateService from "../requests/massUpdates";
import { camelCase } from "../utils/commons";

export class MassUpdateStore {
  @observable rootStore;

  @observable isModalOpen = false;

  @observable massUpdates: IMassUpdate[] = [];

  @observable page: Page | null = null;

  @observable currentMassUpdate: IMassUpdate | null = null;

  constructor(rootStore: RootStore) {
    makeObservable(this);

    this.rootStore = rootStore;
  }

  @computed
  get campaignMassUpdates() {
    const { context } = this.rootStore;

    return this.massUpdates.filter(
      (massUpdate) => massUpdate.attributes.campaignId === context.campaign.id.toString(),
    );
  }

  @action
  setIsModalOpen(isModalOpen: boolean) {
    this.isModalOpen = isModalOpen;
  }

  @action
  setPage(page: Page) {
    this.page = page;
  }

  @action
  setMassUpdates(massUpdates: IMassUpdate[]) {
    this.massUpdates = massUpdates;
  }

  @action
  updateMassUpdateById(massUpdate: IMassUpdate) {
    this.setMassUpdates(this.massUpdates.map((mu) => (mu.id === massUpdate.id ? massUpdate : mu)));
  }

  @action
  setCurrentMassUpdate(massUpdate: IMassUpdate | null) {
    this.currentMassUpdate = massUpdate;
  }

  @action
  updateCurrentMassUpdate(props: Partial<IMassUpdateAttributes>) {
    if (this.currentMassUpdate) {
      this.setCurrentMassUpdate({
        ...this.currentMassUpdate,
        attributes: {
          ...this.currentMassUpdate.attributes,
          ...props,
        },
      });
    }
  }

  async getMassUpdatesAsync() {
    const { data, meta } = await campaignMassUpdateService.getMassUpdatesAsync(
      this.rootStore.context.campaign.id.toString(),
    );

    this.setMassUpdates(data);
    this.setPage(meta.page);
  }

  async createMassUpdateAsync(payload: IMassUpdatePayload) {
    const massUpdate = await campaignMassUpdateService.createMassUpdateAsync(
      this.rootStore.context.campaign.id.toString(),
      payload,
    );

    this.setMassUpdates([...this.massUpdates, massUpdate]);
    this.setCurrentMassUpdate(massUpdate);
  }

  async updateMassUpdateAsync(massUpdateId: string, payload: Partial<IMassUpdatePayload>) {
    const massUpdate = await massUpdateService.putMassUpdateAsync(massUpdateId, payload);

    this.updateMassUpdateById(massUpdate);
    this.updateCurrentMassUpdate(camelCase(payload));
  }

  async deleteMassUpdateAsync(massUpdateId: string) {
    const massUpdate = await massUpdateService.deleteMassUpdateAsync(massUpdateId);

    this.setMassUpdates(this.massUpdates.filter((mu) => mu.id !== massUpdate.id));
  }

  async executeMassUpdateAsync(massUpdateId: string) {
    await massUpdateService.executeMassUpdateAsync(massUpdateId);

    this.updateCurrentMassUpdate({ aasmState: "running" });
  }
}

export default MassUpdateStore;

import * as axiosService from "../axiosRequests";
import { endpoint } from "../../utils/endpoint";
import { IMassUpdate } from "../../types/IMassUpdate";
import { Meta } from "../../types/Meta";
import { IMassUpdatePayload } from "../massUpdates";

interface IGetCampaignMassUpdatesParams {
  page?: number;
}

interface IGetCampaignMassUpdatesResponse {
  data: IMassUpdate[];
  meta: Meta;
}

const campaignMassUpdateEndpoint = (campaignId: string) => endpoint(`app/v1.0/campaigns/${campaignId}/mass_updates`);

export const getMassUpdatesAsync = async (
  campaignId: string,
  params: IGetCampaignMassUpdatesParams = {},
): Promise<IGetCampaignMassUpdatesResponse> => {
  const response = await axiosService.axiosGet(
    campaignMassUpdateEndpoint(campaignId),
    params,
  );

  return response.data;
};

export const createMassUpdateAsync = async (
  campaignId: string,
  payload: IMassUpdatePayload,
): Promise<IMassUpdate> => {
  const response = await axiosService.axiosPost(
    campaignMassUpdateEndpoint(campaignId),
    payload,
  );

  return response.data;
};

export default {
  getMassUpdatesAsync,
  createMassUpdateAsync,
};

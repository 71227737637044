import { action, computed, makeAutoObservable, observable } from "mobx";
import { LeadsStore } from "./LeadsStore";
import * as quoteTemplatesService from "../requests/campaigns/quoteTemplates";
import { createNewFormDataRequest } from "../utils/commons";

export const DND_TEMPLATE_TYPE = "dnd";
export const HTML_TEMPLATE_TYPE = "html";
export const UPLOAD_TEMPLATE_TYPE = "upload";
export const DOCX_DOCUMENT_TYPE = "docx";
export const XLSX_DOCUMENT_TYPE = "xlsx";
export const PDF_DOCUMENT_TYPE = "pdf";

export class QuoteTemplatesStore {
  leadsStore;

  @observable quoteTemplates = [];

  @observable isLoading = false;

  constructor(adminStore) {
    makeAutoObservable(this);
    this.adminStore = adminStore;
    this.campaignId = adminStore.campaignId;
    this.leadsStore = new LeadsStore(this.adminStore.rootStore);
  }

  @computed
  get zeroQuotes() {
    return this.quoteTemplates.length === 0;
  }

  @computed
  get activeTemplates() {
    return this.filterActive(this.quoteTemplates);
  }

  @computed
  get inactiveTemplates() {
    return this.filterInactive(this.quoteTemplates);
  }

  filterActive(templates) {
    return templates.filter(
      (quoteTemplate) => quoteTemplate.attributes.active === true
    );
  }

  filterInactive(templates) {
    return templates.filter(
      (quoteTemplate) => quoteTemplate.attributes.active === false
    );
  }

  @action
  setQuotes(quoteTemplates) {
    this.quoteTemplates = quoteTemplates;
  }

  @action
  setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  findQuoteTemplate(quoteTemplateId) {
    const quote = this.quoteTemplates.find(
      (quoteTemplate) => quoteTemplate.id === quoteTemplateId.toString()
    );
    return quote;
  }

  @action
  modifyQuoteInCache(quoteId, newQuote) {
    const quoteTemplates = [...this.quoteTemplates];

    const quoteIndex = quoteTemplates.findIndex(
      (quote) => quote.id === quoteId.toString()
    );

    if (quoteIndex !== -1) {
      quoteTemplates[quoteIndex] = newQuote;

      this.setQuotes(quoteTemplates);
    }
  }

  @action
  async getQuoteTemplatesAsync() {
    this.setIsLoading(true);
    const quoteTemplates = await quoteTemplatesService.getQuoteTemplates(
      this.campaignId
    );

    quoteTemplates.sort((a, b) => {
      return a.attributes.name
        .toLowerCase()
        .localeCompare(b.attributes.name.toLowerCase());
    });

    const activeTemplates = this.filterActive(quoteTemplates);

    const inactiveTemplates = this.filterInactive(quoteTemplates);

    this.setQuotes([...activeTemplates, ...inactiveTemplates]);
    this.setIsLoading(false);

    return quoteTemplates;
  }

  @action
  async getQuoteTemplateDownloadUrl(quoteTemplateId) {
    this.setIsLoading(true);

    try {
      const downloadUrl = await quoteTemplatesService.getDownloadUrl(this.campaignId, quoteTemplateId);

      return downloadUrl;
    } finally {
      this.setIsLoading(false);
    }
  }

  @action
  async updateQuoteTemplateAsync(quoteId, quoteTemplate) {
    const headers = {};

    let payload = {
      quote_template: quoteTemplate,
    };

    if (quoteTemplate.file) {
      payload = createNewFormDataRequest("quote_template", quoteTemplate);
      headers["Content-Type"] = "multipart/form-data";
    }

    this.setIsLoading(true);

    let quote;

    try {
      quote = await quoteTemplatesService.updateQuoteTemplate(
        this.campaignId,
        quoteId,
        payload,
        headers
      );
  
      this.modifyQuoteInCache(quoteId, quote);
    } finally {
      this.setIsLoading(false);
    }
    
    await this.adminStore.rootStore.authStore.retrieveCurrentUserContext();

    return quote;
  }

  @action
  async createQuoteTemplateAsync(quoteTemplate) {
    let payload = {
      quote_template: quoteTemplate,
    };

    this.setIsLoading(true);

    const headers = {};
    if (quoteTemplate.type === UPLOAD_TEMPLATE_TYPE) {
      headers["Content-Type"] = "multipart/form-data";
      payload = createNewFormDataRequest("quote_template", quoteTemplate);
    }

    try {
      const newQuote = await quoteTemplatesService.createQuoteTemplate(
        this.campaignId,
        payload,
        headers
      );

      this.quoteTemplates.push(newQuote);

      return newQuote;
    } finally {
      this.setIsLoading(false);
    }
  }

  @action
  async deleteQuoteTemplateAsync(quoteTemplateId) {
    this.setIsLoading(true);

    try {
      await quoteTemplatesService.deleteQuoteTemplate(this.campaignId, quoteTemplateId);

      const updatedQuoteTemplates = this.quoteTemplates.filter((quoteTemplate) => quoteTemplate.id !== quoteTemplateId);

      this.setQuotes(updatedQuoteTemplates);
    } finally {
      this.setIsLoading(false);
    }
  }
}

export default QuoteTemplatesStore;
